<template>
  <label :for="id" class="label">{{ labelValue }}</label>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    labelValue: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="sass" scoped>
.label
  font-weight: 600
  font-size: 18px
  color: var(--primary-color-dark-blue)
  margin-bottom: 12px
  pointer-events: none
</style>
