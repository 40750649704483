<template>
  <div class="auth-form">
    <Heading level="1" class="">
      {{ $t('form.authorisation') }}
    </Heading>
    <form
      class="auth-form__form form"
      @submit.prevent
      @keyup.enter="authHandler"
    >
      <div class="form__input">
        <LabelField id="email" :label-value="$t('form.email')" />
        <TextField
          id="email"
          v-model.trim="fields.email.value"
          type="email"
          name="email"
          :placeholder="$t('form.enter_email')"
          required
          :title="$t('form.email_rule')"
          :error-txt="getEmailError"
          :error="fields.email.isValid || !!serverError"
          error-border-visible
          :is-show-icon="!Boolean(serverError)"
          @isFocus="clearField"
        />
      </div>

      <div class="form__input">
        <LabelField id="password" :label-value="$t('form.password')" />
        <TextField
          id="password"
          v-model.trim="fields.password.value"
          type="password"
          name="password"
          :placeholder="$t('form.enter_password')"
          required
          :title="$t('form.password_rule')"
          :error-txt="getpasswordError"
          :error="fields.password.isValid || !!serverError"
          error-border-visible
          :is-show-icon="!Boolean(serverError)"
          @isFocus="clearField"
        />
        <div class="form__input-error">
          <svg-icon
            v-if="getServerError"
            name="error-icon"
            class="error-icon"
          ></svg-icon>
          <span>
            {{ getServerError }}
          </span>
        </div>
      </div>

      <div class="form__btn">
        <ButtonBase color="blue" size="max" @click.native="authHandler">
          <template v-if="!loadingSigninBtn">
            {{ $t('form.sign_in') }}
          </template>
          <Loader v-else color="white" />
        </ButtonBase>
      </div>
    </form>
    <div class="auth-form__signup-btn">
      <ButtonBase
        color="blue-transparent"
        size="max"
        @click.native="goToSignup"
      >
        {{ $t('form.signup_btn') }}
      </ButtonBase>
    </div>
    <div class="auth-form__forgot-password">
      <button
        class="auth-form__forgot-password-btn"
        @click="openModalForgotPassword"
      >
        {{ $t('form.forgot_password.button') }}
      </button>
    </div>
    <Popup :dialog.sync="modalForgotPassword" is-persistent>
      <div class="forgot-password">
        <Heading level="4" class="popup__title">
          {{ $t('form.forgot_password.title') }}
        </Heading>
        <div class="forgot-password__form">
          <TextField
            id="reset-password-email"
            v-model.trim="forgotPasswordEmail"
            type="email"
            name="reset-password-email"
            :placeholder="$t('form.forgot_password.placeholder')"
            required
            :error="isForgotPasswordEmailError"
            :error-txt="forgotPasswordEmailErrorText"
            @isFocus="clearForgotPassEmailField"
            @blur="blurForgotPasswordEmailHandler"
          />
          <ButtonBase
            color="blue"
            size="max"
            class="popup__btn"
            :disabled="isForgotPasswordEmailInvalid"
            @click.native="resetPasswordHandler"
          >
            <template v-if="!loadingForgotPasswordBtn">
              {{ $t('form.forgot_password.submit_button') }}
            </template>
            <Loader v-else color="white" />
          </ButtonBase>
        </div>
        <button
          type="button"
          class="popup__close-btn"
          @click="closeModalForgotPassword"
        >
          <svg-icon name="popup-close"></svg-icon>
        </button>
      </div>
    </Popup>
  </div>
</template>

<script>
import TextField from '@/elements/Inputs/TextField.vue';
import LabelField from '@/elements/Inputs/LabelField.vue';
import ButtonBase from '@/elements/Buttons/ButtonBase.vue';
import Heading from '@/elements/Heading/Heading.vue';
import Loader from '@/elements/Loader/Loader.vue';
import Popup from '@/elements/Popup/Popup.vue';

import { required, email, minLength } from 'vuelidate/lib/validators';

export default {
  components: {
    TextField,
    LabelField,
    ButtonBase,
    Heading,
    Loader,
    Popup,
  },
  data() {
    return {
      fields: {
        email: {
          value: '',
          isValid: false,
        },
        password: {
          value: '',
          isValid: false,
        },
      },
      serverError: null,
      serverErrorForgotPassword: null,

      modalForgotPassword: false,
      forgotPasswordEmail: '',

      loadingSigninBtn: false,
      loadingForgotPasswordBtn: false,
    };
  },
  computed: {
    getServerError() {
      return this.serverError && this.isFieldHasValue
        ? this.$t('form.wrong_credentials')
        : '';
    },
    getEmailError() {
      if (!this.$v.fields.email.value.required) {
        return this.$t('form.empty_field_error');
      }
      if (!this.$v.fields.email.value.email) {
        return this.$t('form.email_error');
      }

      return '';
    },
    getpasswordError() {
      if (!this.$v.fields.password.value.required) {
        return this.$t('form.empty_field_error');
      }
      if (!this.$v.fields.password.value.minLength) {
        return this.$t('form.password_minlength_error');
      }

      return '';
    },
    isFieldHasValue() {
      return (
        !!this.fields.email.value.length && !!this.fields.password.value.length
      );
    },
    isForgotPasswordEmailInvalid() {
      return this.$v.forgotPasswordEmail.$invalid;
    },
    isForgotPasswordEmailError() {
      return (
        (this.$v.forgotPasswordEmail.$dirty &&
          !this.$v.forgotPasswordEmail.required) ||
        (this.$v.forgotPasswordEmail.$dirty &&
          this.$v.forgotPasswordEmail.$invalid) ||
        !!this.serverErrorForgotPassword
      );
    },
    forgotPasswordEmailErrorText() {
      if (!this.$v.forgotPasswordEmail.required) {
        return this.$t('form.forgot_password.validation.user_email.empty');
      }
      if (!this.$v.forgotPasswordEmail.email) {
        return this.$t('form.forgot_password.validation.user_email.invalid');
      }
      if (this.serverErrorForgotPassword) {
        return this.$t(
          'form.forgot_password.validation.user_email.not_existing'
        );
      }

      return '';
    },
  },

  methods: {
    blurForgotPasswordEmailHandler() {
      this.$v.forgotPasswordEmail.$touch();
    },
    clearField(name) {
      this.fields[name].isValid = false;
      this.serverError = null;
    },
    async authHandler() {
      this.fields.email.isValid = this.$v.fields.email.value.$invalid;
      this.fields.password.isValid = this.$v.fields.password.value.$invalid;

      let data = {
        email: this.fields.email.value,
        password: this.fields.password.value,
      };

      if (
        !this.$v.fields.email.value.$invalid &&
        !this.$v.fields.password.value.$invalid
      ) {
        this.loadingSigninBtn = true;
        this.serverError = await this.$store.dispatch('authorization', data);
        this.loadingSigninBtn = false;
      }
    },
    async resetPasswordHandler() {
      if (this.isForgotPasswordEmailInvalid) {
        return;
      }

      this.loadingForgotPasswordBtn = true;

      const data = {
        email: this.forgotPasswordEmail,
      };

      this.serverErrorForgotPassword = await this.$store.dispatch(
        'resetPassword',
        data
      );
      this.loadingForgotPasswordBtn = false;

      if (!this.serverErrorForgotPassword) {
        this.closeModalForgotPassword();
        this.$store.dispatch('setNotification', {
          type: 'success',
          title: this.$t('form.forgot_password.notification.title'),
          text: this.$t('form.forgot_password.notification.text'),
        });
      }
    },
    openModalForgotPassword() {
      this.resetForgotPasswordEmail();
      this.modalForgotPassword = true;
    },
    closeModalForgotPassword() {
      this.modalForgotPassword = false;
    },
    resetForgotPasswordEmail() {
      this.forgotPasswordEmail = '';
      this.$v.forgotPasswordEmail.$reset();
      this.serverErrorForgotPassword = null;
    },
    clearForgotPassEmailField() {
      this.$v.forgotPasswordEmail.$reset();
      this.serverErrorForgotPassword = null;
    },
    goToSignup() {
      this.$router.push({ name: 'Signup' });
    },
  },

  validations: {
    fields: {
      email: {
        value: {
          required,
          email,
        },
      },
      password: {
        value: {
          required,
          minLength: minLength(6),
        },
      },
    },
    forgotPasswordEmail: {
      required,
      email,
    },
  },
};
</script>

<style lang="sass">
.auth-form
  background-color: var(--primary-color-white)
  box-shadow: var(--primary-border-shadow-1)
  border-radius: var(--primary-border-radius)
  border: 1px solid var(--primary-color-light-grey-3)
  padding: 70px 0
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column

  @media screen and (max-width: 600px)
    border-radius: 0
    border-top: none
    border-left: none
    border-right: none
    padding: 32px 0 48px

  @media screen and (max-width: 600px)
    border-bottom: none

.auth-form .title
  @media screen and (max-width: 767px)
    font-size: 50px
    line-height: 64px

  @media screen and (max-width: 600px)
    font-size: 32px
    line-height: 38px
    margin-bottom: 24px

.auth-form .primary-btn
  @media screen and (max-width: 600px)
    height: 44px
    font-size: 14px
    line-height: 17px

.form
  min-width: 420px
  @media screen and (max-width: 500px)
    min-width: 100%
    padding: 0 16px

.form__input
  display: flex
  flex-direction: column
  align-items: flex-start
  margin-bottom: 40px
  position: relative

  @media screen and (max-width: 1199px)
    margin-bottom: 44px

  @media screen and (max-width: 600px)
    margin-bottom: 28px

  .label
    @media screen and (max-width: 600px)
      font-size: 16px
      line-height: 19px
      margin-bottom: 5px

  .input
    border: 1px solid rgba(40, 59, 89, 0.3)

    @media screen and (max-width: 600px)
      height: 44px
      padding: 0 12px
      font-size: 14px

.form__input-error
  display: flex
  align-items: center
  font-size: 14px
  color: var(--primary-color-error)
  position: absolute
  bottom: -20px
  left: 0

.error-icon
  width: 14px
  height: 14px
  margin-right: 4px

.auth-form__signup-btn
  width: 100%
  max-width: 420px
  margin-top: 24px

  @media screen and (max-width: 600px)
    margin-top: 16px

  @media screen and (max-width: 500px)
      max-width: 100%
      padding: 0 16px

.auth-form__forgot-password
  margin-top: 24px

  @media screen and (max-width: 600px)
    margin-top: 14px

.auth-form__forgot-password-btn
  font-size: 18px
  line-height: 21px
  color: var(--btn-color-blue)

  @media screen and (max-width: 600px)
    font-size: 14px
    line-height: 16px

.forgot-password
  @media screen and (max-width: 767px)
    margin-top: 60px

  @media screen and (max-width: 767px)
    margin-top: 34px

.forgot-password__form
  display: flex
  flex-direction: column
  gap: 48px
  margin-top: 24px
  padding-top: 24px
  border-top: 1px solid var(--primary-color-light-grey-3)

  @media screen and (max-width: 767px)
    gap: 32px

  @media screen and (max-width: 600px)
    margin-top: 16px
    padding-top: 16px

.forgot-password .input,
.forgot-password .primary-btn
  @media screen and (max-width: 767px)
    height: 56px

  @media screen and (max-width: 600px)
    height: 44px

.forgot-password .input::-webkit-input-placeholder,
.forgot-password .input::placeholder,
.forgot-password .primary-btn
  @media screen and (max-width: 600px)
    font-size: 14px
    line-height: 17px
</style>
