<template>
  <div class="auth">
    <div class="auth__content">
      <div class="auth__form-box">
        <AuthForm />
      </div>
    </div>
  </div>
</template>

<script>
import AuthForm from '@/components/Auth/AuthForm.vue';

export default {
  components: {
    AuthForm,
  },
  created() {
    this.$store.commit('LOGOUT_USER');
  },
};
</script>

<style lang="sass" scoped>
.auth
  @media screen and (max-width: 600px)
    margin-left: -15px
    margin-right: -15px

.auth__content
  max-width: 688px
  margin: 0 auto
  padding: 68px 0
  text-align: center

  @media screen and (max-width: 600px)
    max-width: 100%
    padding: 0

.auth__location
  margin-bottom: 32px

  @media screen and (max-width: 1199px)
    margin-bottom: 40px
</style>
